<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <template v-if="isLoading = (isLoadingAsset || isLoadingReturnData || isLoadingInspectInstructions || isLoadingCustomAttributes || isLoadingGradingSet || isLoadingContainers)">
                <div class="container mt-5">
                    Loading product grading rules...
                </div>
            </template>

            <template v-else-if="isSavingAsset">
                <div class="container mt-5">
                    Saving asset...
                </div>
            </template>

            <template v-else-if="isSavingContainer">
                <div class="container mt-5">
                    Saving container...
                </div>
            </template>

            <form action="#" v-else>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <h3 class="section-title-sm">
                                Grade asset
                            </h3>

                            <div class="table-responsive">
                                <table class="table-first-level">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Description</th>
                                            <th>Origin country</th>
                                            <th>License plate</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="5">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th class="border-bottom-none">
                                                                {{ asset.SKU }}
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                {{ asset.DESCRIPTION }}
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <template v-if="asset.COUNTRY_OF_ORIGIN">
                                                                    {{ asset.COUNTRY_OF_ORIGIN }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                {{ asset.LICENSE_PLATE }}
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="p-0 border-radius-none border-top-td">
                                                                <table class="w-100">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="w-50 bgr-grey border-right-td border-radius-none vertical-top">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-lg-6">
                                                                                        <div class="form-group">
                                                                                            <label class="label">
                                                                                                Account
                                                                                            </label>

                                                                                            <p>
                                                                                                {{ receivedReturn.MERCHANT_ACCOUNT_NAME }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-12 col-lg-6">
                                                                                        <div class="form-group">
                                                                                            <label class="label">
                                                                                                Channel
                                                                                            </label>

                                                                                            <p>
                                                                                                {{ receivedReturn.PORTAL_NAME }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-12 col-lg-6">
                                                                                        <div class="form-group">
                                                                                            <label class="label">
                                                                                                Return policy
                                                                                            </label>

                                                                                            <p>
                                                                                                {{ receivedReturn.MERCHANT_SETUP_NAME }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-12 col-lg-6">
                                                                                        <div class="form-group">
                                                                                            <label class="label">
                                                                                                Return reference
                                                                                            </label>

                                                                                            <p>
                                                                                                {{ receivedReturn.RETURN_REFERENCE }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-12">
                                                                                        <div class="form-group" v-if="!isLoadingProductData">
                                                                                            <label class="label">
                                                                                                Return reason
                                                                                            </label>

                                                                                            <p>
                                                                                                <template v-if="!isUnexpectedProduct && gradingProduct.RETURN_REASON">
                                                                                                    {{ gradingProduct.RETURN_REASON }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    -
                                                                                                </template>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <template v-if="productCustomAttributes">
                                                                                    <CustomAttributesDisplay :customAttributes="productCustomAttributes"/>
                                                                                </template>
                                                                            </td>

                                                                            <td class="w-50 border-radius-none vertical-top">
                                                                                <template v-if="grades">
                                                                                    <template v-if="isSavingGrade">
                                                                                        Grading asset...
                                                                                    </template>

                                                                                    <template v-else-if="isGradingAsset">
                                                                                        <div class="form-group">
                                                                                            <div class="row">
                                                                                                <div class="col-10">
                                                                                                    <label class="label">
                                                                                                        Grade instruction
                                                                                                    </label>

                                                                                                    <p>
                                                                                                        <template v-if="inspectInstructionMessage">
                                                                                                            {{ inspectInstructionMessage }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div class="col-2 text-right">
                                                                                                    <template v-if="instructionImages && instructionImages.length > 0">
                                                                                                        <ImageGallery :images="instructionImages"
                                                                                                                      :onlyShowFirstImage="true"
                                                                                                                      idAttribute="RTN_WFE_ID"
                                                                                                                      urlAttribute="RTN_WFE_URL"
                                                                                                                      class="sm">
                                                                                                        </ImageGallery>
                                                                                                    </template>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <template v-if="Object.keys(grades).length === 2">
                                                                                            <div class="row mt-5">
                                                                                                <template v-for="grade in grades">
                                                                                                    <div class="col" :key="grade.RTN_GRL_ID">
                                                                                                        <button type="button" class="btn btn--light-grading w-100 h-100" :class="'grade--' + grade.RTN_GRL_GRADE" @click.prevent="saveGrade(grade.RTN_GRL_GRADE)">
                                                                                                            {{ grade.RTN_GRL_LABEL }}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </template>
                                                                                            </div>
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <div class="modal-content mt-5" :key="gradeIndex">
                                                                                                <div class="modal-body text-center">
                                                                                                    <h4 class="py-4 my-3">
                                                                                                        {{ currentGrade.RTN_GRL_LABEL }}
                                                                                                    </h4>
                                                                                                </div>

                                                                                                <div class="modal-footer">
                                                                                                    <button type="button" class="btn btn-danger" @click="nextGrade()"
                                                                                                            v-if="gradeIndex > 0">
                                                                                                        No
                                                                                                    </button>

                                                                                                    <button type="button" class="btn btn-success" @click="saveGrade(currentGrade.RTN_GRL_GRADE)">
                                                                                                        Yes
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </template>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        <div class="form-group" v-if="!isAddingPictures">
                                                                                            <div class="row">
                                                                                                <div class="col-12 col-lg-8">
                                                                                                    <span class="grading-result-title mb-4">
                                                                                                        Received grade
                                                                                                    </span>

                                                                                                    <div class="row mt-5 pt-2">
                                                                                                        <div class="col-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="label">Disposition type</label>

                                                                                                                <p>
                                                                                                                    {{ asset.DISPOSITION_TYPE | capitalize }}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div class="col-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="label">Storage</label>

                                                                                                                <p>
                                                                                                                    {{ asset.STORAGE_TYPE }}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="row">
                                                                                                        <div class="col-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="label">Vendor</label>

                                                                                                                <p>
                                                                                                                    {{ asset.DEFAULT_VENDOR_NAME ?? '-' }}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-12 col-lg-4 text-center text-lg-right">
                                                                                                    <span class="result m-3" :class="'grade--' + asset.GRADE">
                                                                                                        <strong>
                                                                                                            {{ asset.GRADE }}
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="form-group">
                                                                                            <CustomAttributesForm :customAttributes="customAttributes" :errors="errors" ref="customAttrForm" :class="isAddingPictures ? 'd-none' : 'd-block'"/>

                                                                                            <Webcam model="Asset"
                                                                                                    :modelPk="asset.ID"
                                                                                                    imageCountLimit="5"
                                                                                                    fileUploadCategory="assetGrade"
                                                                                                    @startedAddingPictures="isAddingPictures = true"
                                                                                                    @stoppedAddingPictures="isAddingPictures = false"
                                                                                                    :shouldStopAddingPictures="shouldStopAddingPictures"
                                                                                            >
                                                                                            </Webcam>

                                                                                            <template v-if="receiveAndGradeFlow == 1 && !isAddingPictures">
                                                                                                <div class="d-flex justify-content-between">
                                                                                                    <div class="d-block w-100">
                                                                                                        <label class="label">
                                                                                                            Container
                                                                                                        </label>

                                                                                                        <model-list-select
                                                                                                                :list="filteredContainers"
                                                                                                                option-value="value"
                                                                                                                option-text="label"
                                                                                                                v-model="asset.CONTAINER_ID"
                                                                                                                required
                                                                                                        >
                                                                                                        </model-list-select>
                                                                                                    </div>

                                                                                                    <div class="d-block mx-3 text-center">
                                                                                                        <label class="label">
                                                                                                            Full
                                                                                                        </label>

                                                                                                        <label class="checkbox-container container-full m-0 p-0 d-flex justify-content-center align-items-center">
                                                                                                            <input type="checkbox" v-model="containerIsFull">
                                                                                                            <span class="checkmark"></span>
                                                                                                        </label>
                                                                                                    </div>

                                                                                                    <div class="d-block text-center">
                                                                                                        <label class="label">
                                                                                                            New
                                                                                                        </label>

                                                                                                        <button type="button" class="btn btn-primary btn-create-container" @click.prevent="showCreateContainerModal = true">
                                                                                                            +
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <InputFieldError attribute="CONTAINER_ID" :errors="errors"/>
                                                                                            </template>
                                                                                        </div>
                                                                                    </template>
                                                                                </template>

                                                                                <template v-else>
                                                                                    There is no grading set for this product.
                                                                                </template>

                                                                                <InputFieldError attribute="global" :errors="errors"/>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back to overview
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="saveAsset()" v-if="!isGradingAsset">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

        </main>

        <div class="confirmation-overlay" v-if="showCreateContainerModal">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="mb-0">Create container</h5>
                    </div>

                    <div class="modal-body">
                        <template v-if="isCreatingContainer">
                            Creating container {{ newContainer.licensePlate }}...
                        </template>

                        <template v-else>
                            <div class="form-group">
                                <label class="label">
                                    License plate
                                </label>

                                <input type="text" class="form-control" v-model="newContainer.licensePlate">
                                <InputFieldError attribute="LICENSE_PLATE" :errors="errors"/>
                            </div>

                            <div class="form-group">
                                <label class="label">
                                    Container type
                                </label>

                                <template v-if="isLoadingContainerTypes">
                                    <p>Loading container types...</p>
                                </template>

                                <template v-else>
                                    <v-select v-model="newContainer.type" :filterable="true" :searchable="true" :clearable="false"
                                              :options="containerTypes"></v-select>

                                    <InputFieldError attribute="TYPE" :errors="errors"/>
                                </template>
                            </div>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="showCreateContainerModal = false" :disabled="isCreatingContainer">
                            Cancel
                        </button>

                        <button type="button" class="btn btn-success" @click="createContainer()" :disabled="isCreatingContainer">
                            Create container
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import _ from 'lodash';
    import CustomAttributesForm from '@/components/_CustomAttributesForm.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';
    import {ModelListSelect} from 'vue-search-select';
    import ImageGallery from '@/components/_ImageGallery.vue';
    import Webcam from '@/components/_Webcam.vue';
    import CustomAttributesDisplay from '@/components/_CustomAttributesDisplay.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'CustomAttributesForm': CustomAttributesForm,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
            'ModelListSelect': ModelListSelect,
            'ImageGallery': ImageGallery,
            'Webcam': Webcam,
            'CustomAttributesDisplay': CustomAttributesDisplay,
        },
        data() {
            return {
                flow: this.$route.query.flow,
                manifestId: this.$route.query.manifestId,
                manifestReference: this.$route.query.manifestReference,
                isLoading: false,
                isLoadingAsset: false,
                isLoadingReturnData: false,
                isLoadingProductData: false,
                isLoadingInspectInstructions: false,
                isLoadingCustomAttributes: false,
                isLoadingGradingSet: false,
                isLoadingContainers: false,
                isLoadingContainerTypes: false,
                isLoadingCurrentLocation: false,
                isSavingAsset: false,
                isSavingContainer: false,
                isSavingGrade: false,
                isUnexpectedProduct: false,
                isCreatingContainer: false,
                instructionImages: null,
                inspectInstructionMessage: null,
                gradingProduct: null,
                gradingArticle: null,
                isGradingAsset: false,
                asset: {},
                customAttributes: null,
                productCustomAttributes: null,
                errors: {},
                grades: null,
                currentGrade: null,
                gradeIndex: null,
                containers: null,
                filteredContainers: null,
                containerIsFull: false,
                selectedLocationId: null,
                showCreateContainerModal: false,
                newContainer: {
                    licensePlate: null,
                    type: null
                },
                containerTypes: null,
                receiveAndGradeFlow: 1,
                receivedReturn: null,
                isAddingPictures: false,
                shouldStopAddingPictures: false,
            };
        },
        created() {
            this.isGradingAsset = true;
            this.isUnexpectedProduct = !this.$route.query.productId && this.$route.query.articleId;

            this.getAsset();
            this.getGradingSet();
            this.getContainerTypes();
            this.getReturnData();
            this.getProductCustomAttributes();

            this.isLoadingCurrentLocation = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                this.isLoadingCurrentLocation = false;
                this.selectedLocationId = data.id;

                this.getContainers();
            });

            if (!this.isUnexpectedProduct) {
                this.$store.dispatch('products/getGradingProduct', this.$route.query.productId).then((data) => {
                    this.isLoadingProductData = false;
                    this.gradingProduct = data;

                    this.getProductInstructions();
                });
            } else {
                const url = new URL(this.$store.state.baseUrl + 'articles/get-by-id');
                url.searchParams.append('articleId', this.$route.query.articleId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingProductData = false;
                    this.gradingArticle = data.data;

                    this.getArticleInstructions();
                });
            }
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'AssetOverview',
                    query: {
                        returnId: this.$route.query.returnId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            next() {
                this.$router.push({
                    name: 'AssetOverview',
                    query: {
                        returnId: this.$route.query.returnId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            getAsset() {
                this.isLoadingAsset = true;

                this.$store.dispatch('assets/getAsset', [this.$route.query.returnId, this.$route.query.assetId]).then((data) => {
                    this.isLoadingAsset = false;
                    this.asset = data;

                    /* Convert CONTAINER_ID to string so the v-select dropdown shows the label instead of the ID. */
                    if (this.asset.CONTAINER_ID) {
                        this.asset.CONTAINER_ID = this.asset.CONTAINER_ID.toString();
                    }

                    this.getCustomAttributes();
                    this.getReceiveAndGradeFlow();
                });
            },
            getCustomAttributes() {
                this.isLoadingCustomAttributes = true;

                this.$store.dispatch('assets/getCustomAttributes', [this.$route.query.assetId, null, null, 'RETURN_DOCK_GRADE_ASSET']).then((data) => {
                    this.isLoadingCustomAttributes = false;
                    this.customAttributes = data;
                });
            },
            getProductCustomAttributes() {
                if (this.isUnexpectedProduct) {
                    return;
                }

                const url = new URL(this.$store.state.baseUrl + 'products/get-returnbird-custom-attributes');
                url.searchParams.append('productId', this.$route.query.productId);

                this.$http.get(url.href).then((data) => {
                    if (data.data) {
                        this.productCustomAttributes = {};

                        for (const [key, customAttribute] of Object.entries(data.data)) {
                            if (customAttribute.value) {
                                this.productCustomAttributes[key] = customAttribute;
                            }
                        }
                    }
                });
            },
            getProductInstructions() {
                this.isLoadingInspectInstructions = true;

                this.$store.dispatch('products/getInspectInstructions', [this.gradingProduct.PRODUCT_ID, this.gradingProduct.SKU]).then((data) => {
                    this.isLoadingInspectInstructions = false;

                    if (data) {
                        this.inspectInstructionMessage = data.message;
                        this.instructionImages = data.images;
                    }
                });
            },
            getArticleInstructions() {
                this.isLoadingInspectInstructions = true;

                const url = new URL(this.$store.state.baseUrl + 'products/get-instructions');
                url.searchParams.append('sku', this.gradingArticle.RTN_ARL_SKU);
                url.searchParams.append('type', 'inspect');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingInspectInstructions = false;

                    if (data.data) {
                        this.inspectInstructionMessage = data.data.message;
                        this.instructionImages = data.data.images;
                    }
                });
            },
            getGradingSet() {
                this.isLoadingGradingSet = true;

                this.$store.dispatch('assets/getGradingLabels', this.$route.query.assetId).then((data) => {
                    this.isLoadingGradingSet = false;
                    this.grades = data;

                    if (this.grades) {
                        /* Set the last grade (Grade D) as grade which is currently shown. */
                        this.gradeIndex = Object.keys(this.grades).length - 1;
                        this.currentGrade = this.grades[Object.keys(this.grades)[this.gradeIndex]];
                    }
                });
            },
            getContainers() {
                this.isLoadingContainers = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-all');
                url.searchParams.set('includeAssets', false);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainers = false;
                    this.containers = data.data;
                    this.filterContainers();
                });
            },
            getReturnData() {
                this.isLoadingReturnData = true;

                this.$store.dispatch('returns/getReceivedReturn', this.$route.query.returnId).then((data) => {
                    this.isLoadingReturnData = false;
                    this.receivedReturn = data;
                });
            },
            nextGrade() {
                if (this.gradeIndex > 0) {
                    this.gradeIndex--;
                    this.currentGrade = this.grades[Object.keys(this.grades)[this.gradeIndex]];
                }
            },
            saveGrade(grade) {
                this.isSavingGrade = true;

                let url = new URL(this.$store.state.baseUrl + 'assets/save-grade');
                url.searchParams.set('assetId', this.$route.query.assetId);

                let params = new URLSearchParams();
                params.append('Asset[GRADE]', grade);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingGrade = false;

                    if (data.data.code === 100) {
                        this.asset = data.data.data;

                        if (!this.asset.CONTAINER_ID) {
                            /* If the asset has been graded but has not been added to a container, add it to the unpacked list. */
                            this.$store.commit('assets/addUnpackedAsset', this.asset);
                        }

                        this.isGradingAsset = false;
                    }
                });
            },
            saveAsset() {
                if (this.isAddingPictures) {
                    /* Close the Webcam component without continuing.
                     * This is done to prevent the operator from accidentally ending the Grading process too soon. */
                    this.shouldStopAddingPictures = true;

                    this.$nextTick(() => {
                        this.shouldStopAddingPictures = false;
                    });

                    return false;
                }

                if (this.receiveAndGradeFlow == 1 && !this.asset.CONTAINER_ID) {
                    this.errors = {CONTAINER_ID: 'Container is required.'};
                    return false;
                }

                this.errors = {};
                this.isSavingAsset = true;

                let url = new URL(this.$store.state.baseUrl + 'assets/save');
                url.searchParams.set('assetId', this.$route.query.assetId);

                let params = new URLSearchParams();
                params.append('Asset[DISPOSITION_TYPE]', this.asset.DISPOSITION_TYPE);
                params.append('Asset[DISPOSITION_STATUS]', 'inspected');

                if (this.asset.CONTAINER_ID) {
                    params.append('Asset[CONTAINER_ID]', this.asset.CONTAINER_ID);
                }

                params.append('Asset[CUSTOM_ATTRIBUTE_OPTION]', 'RETURN_DOCK_GRADE_ASSET');
                let customAttributesData = this.$refs.customAttrForm.getData();

                _.forOwn(customAttributesData, function (value, key) {
                    if (value !== null) {
                        params.append('Asset[' + key + ']', value);
                    }
                });

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingAsset = false;

                    if (data.data.code === 100) {
                        let asset = data.data.data;
                        this.$store.commit('assets/deleteUnpackedAsset', asset);
                        this.$store.commit('assets/addInspectedAsset', asset);

                        /* Update custom attributes in store */
                        _.forOwn(customAttributesData, (value, key) => {
                            this.customAttributes[key].value = value;
                        });

                        this.$store.commit('assets/setCustomAttributes', [asset.ID, this.customAttributes]);
                        this.isGradingAsset = false;

                        if (this.containerIsFull) {
                            this.setContainerFull(asset.CONTAINER_ID);
                        } else {
                            this.next();
                        }
                    } else {
                        if (data.data.message && data.data.message === 'Container full') {
                            /* If the selected container is full, unselect it and reload the available containers. */
                            this.asset.CONTAINER_ID = null;
                            this.getContainers();
                        }

                        this.errors = data.data.errors;
                    }
                });
            },
            setContainerFull(containerId) {
                this.isSavingContainer = true;
                let url = new URL(this.$store.state.baseUrl + 'containers/' + containerId);
                let params = new URLSearchParams();
                params.append('IS_FULL', 1);

                this.$http.put(url.href, params).then((data) => {
                    this.isSavingContainer = false;

                    if (data.status === 200) {
                        this.next();
                    } else {
                        this.errors = {global: 'Unable to mark container as full.'};
                    }
                });
            },
            getContainerTypes() {
                this.isLoadingContainerTypes = true;

                this.$store.dispatch('containers/getContainerTypes').then((data) => {
                    this.isLoadingContainerTypes = false;
                    this.containerTypes = data;
                });
            },
            createContainer() {
                if (!this.newContainer.licensePlate) {
                    this.errors = {LICENSE_PLATE: 'License plate is required'};
                    return false;
                }

                if (!this.newContainer.type) {
                    this.errors = {TYPE: 'Container type is required'};
                    return false;
                }

                this.isCreatingContainer = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/create-container');
                let params = new URLSearchParams();
                params.append('Container[LICENSE_PLATE]', this.newContainer.licensePlate);
                params.append('Container[TYPE]', this.newContainer.type);

                this.$http.post(url, params).then((data) => {
                    this.isCreatingContainer = false;

                    if (data.data.code === 100) {
                        this.newContainer = {
                            licensePlate: null,
                            type: null
                        };

                        this.containers.push(data.data.data);

                        this.filterContainers();
                        this.showCreateContainerModal = false;
                        this.asset.CONTAINER_ID = data.data.data.ID;
                    } else {
                        this.errors = data.data.errors;
                    }
                });
            },
            filterContainers() {
                /* Retrieve containers which are not full and have the same disposition type and return bird account ID
                   as the asset, or do not have a disposition type or return bird account ID yet */
                this.filteredContainers = [];

                _.forOwn(this.containers, (container) => {
                    if (container.IS_FULL == 0 &&
                        (container.DISPOSITION_TYPE == this.asset.DISPOSITION_TYPE || container.DISPOSITION_TYPE == null) &&
                        (container.RETURNBIRD_ACCOUNT_ID == this.asset.RETURNBIRD_ACCOUNT_ID || container.RETURNBIRD_ACCOUNT_ID == null) &&
                        (container.STORAGE_TYPE == this.asset.STORAGE_TYPE || container.STORAGE_TYPE == null)) {
                        this.filteredContainers.push({value: parseInt(container.ID), label: container.LICENSE_PLATE});
                    }
                });
            },
            getReceiveAndGradeFlow() {
                const url = new URL(this.$store.state.baseUrl + 'location-merchant-settings/get-selected-receive-and-grade-flow');
                url.searchParams.append('accountId', this.asset.RETURNBIRD_ACCOUNT_ID);

                this.$http.get(url.href).then((data) => {
                    if (data.data.code === 100) {
                        this.receiveAndGradeFlow = data.data.data;
                    }
                });
            },
        },
        watch: {
            /* Update the list of containers when this.asset.DISPOSITION_TYPE is changed. */
            'asset.DISPOSITION_TYPE': function() {
                if (!this.isLoading) {
                    this.filterContainers();
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped lang="scss">
    h4 {
        font-weight: bold;
        font-size: 18px;
    }

    .modal-body h4 {
        font-size: 20px;
    }

    .btn-create-container {
        padding: 10px 15px;
    }

    .checkbox-container.container-full {
        width: 40px;
        height: 40px;

        .checkmark {
            position: relative;
        }
    }

    .table-second-level tbody tr td {
        color: #1d2640;
    }

    .scrollable-content {
        padding: 30px 0;
    }

    .btn--light-grading {
        min-height: 200px;
        border-radius: 10px;
        padding: 30px 32px;
        font-size: 21px;
        background-color: #379CF6;
        color: #fff;

        &:hover {
            background-color: darken(#379CF6, 5%);
            color: #fff;
        }

        &.grade--A {
            background-color: #4ae2c3;

            &:hover {
                background-color: darken(#4ae2c3, 10%);
            }
        }

        &.grade--B {
            background-color: #ff5e66;

            &:hover {
                background-color: darken(#ff5e66, 5%);
            }
        }
    }
</style>
